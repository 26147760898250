import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid, Tooltip, Container, Button } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import Atoms from './recoil/atoms';
import apiRequests from './apiRequests';

function TabPanel({ children, value, index, ...other }) {
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box p={3}>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom noWrap>
        {children}
          </Typography>
          </Box>
        )}
      </div>
    );
  }
  
const ScrollableTabs = (props) => {
    const useStyles = makeStyles((theme) => ({
        heroButtons: {
          marginTop: theme.spacing(4),
        },
    }));

    const [currentTab, setCurrentTab] = React.useState(0);
    const classes = useStyles();
    const [locations, setLocations] = React.useState([]);
    const [loggedInUser, setLoggedInUser] = useRecoilState(Atoms.loggedInUser);
    const [locationIds, setLocationIds] = useRecoilState(Atoms.locationIds);
  
    const handleChange = (event, newTab) => {
      setCurrentTab(newTab);
      let currentLoc = locations.find(l => l.store.toUpperCase() === event.target.innerText)
      if(currentLoc) {         
        props.setCurrentLocation(currentLoc.locationId);
      }
    };

    const setThislocations = async(locs) => {
        setLocations(locations => [
            ...locations,
            locs
        ]);
        console.debug(locations)
    }

    const getLocations = async () => {
        if(locationIds.length > 0) {
            locationIds.map(async(location) => {
                setThislocations(location);
            })
        }
        else {
            await loggedInUser.locationId.map(async(loc) => {
                await apiRequests.getLocationByExternalId(loc, loggedInUser.token).then((response) => {
                    setThislocations(response.data)
                        if(!locationIds.some(l => l.id === response.data.locationId)) {
                        setLocationIds(locationIds => [
                            ...locationIds,
                            response.data
                        ])
                    }
                })
            })
        }
        
    }

    useEffect(() => {
        if(locations.length === 0) {
            getLocations();
        }
    },[]);
  
    return (
      <div>
        <AppBar 
            position="static"
            style={{backgroundColor:'#a00'}}
            >
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant={"scrollable"}
            scrollButtons={"on"}
          >
            {locations.length > 0 ? (
            locations.map((loc) => (
              <Tab key={loc.store} label={loc.store} />
            ))
            ):(null)}
          </Tabs>
        </AppBar>
        {locations.length > 0 ? (
        locations.map((loc, index) => (
          <TabPanel key={loc.store} value={currentTab} index={index}>
            {loc.store}
          </TabPanel>
        ))
        ):(null)}    
          <Container maxWidth="sm">
          <Typography 
            variant="h6" 
            align="center" 
            color="textSecondary" 
            paragraph
            >
            Hantering av dina erbjudanden
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Tooltip title="Skapa ett ny erbjudande" interactive>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={props.createAd}
                  >
                  Lägg till erbjudande
                </Button>
                </Tooltip>
              </Grid>
              {/* <Grid item>
                <Button variant="outlined" color="primary">
                  Behöver du hjälp?
                </Button>
              </Grid> */}
            </Grid>
          </div>
        </Container>
        </div>
    )
}

export default ScrollableTabs;
