import axios from "axios";
import settings from "./settings";

const formatAuthHeader = (token) => {
  const authHeader = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return authHeader;
};

const host = settings.host;

async function login(username, password) {
  try {
    const result = await axios.post(host + "/auth/local", {
      identifier: username,
      password: password,
    });
    return result;
  } catch (e) {
    return e.response;
  }
}

async function saveAd(data, token) {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const result = await axios.put(
      host + "/ads/" + data.id,
      formData,
      formatAuthHeader(token),     
    );
    return result;
  } catch (e) {
    //Todo
    return e;
  }
}

async function getLocationByExternalId(id) {
  const result = await axios.get(host + '/locations/externalId/' + id);
  return result;
}

async function createAd(data, token) {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const result = await axios.post(
      host + "/ads",
      formData,
      formatAuthHeader(token), {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }  
    }  
    );
    return result;
  } catch (e) {
    //Todo
    return e;
  }
}

async function deleteAd(id, token) {
  try {
    const result = await axios.delete(
      host + "/ads/" + id,
      formatAuthHeader(token) 
    );
    return result;
  } catch (e) {
    //Todo
    return e;
  }
}

async function deleteImage(id, token) {
  try {
    if(id !== 196) {
    const result = await axios.delete(
      host + "/upload/files/" + id,
      formatAuthHeader(token) 
    );

    return result;
    }
  } catch (e) {
    //Todo
    return e;
  }
}

async function uploadImage(image, token) {
  try {
    let imageToUpload = image[0] ? image[0] : image;
    const formData = new FormData();
    formData.append("files", imageToUpload, imageToUpload.name);
    formData.append("fileInfo", JSON.stringify({
      alternativeText:"",
      caption:"",
      name:null
    }));
    const result = await axios.post(
      host + "/upload",
      formData,
      formatAuthHeader(token)
    );
    return result;
  } catch (e) {
    //Todo
    return e;
  }
}

async function uploadByUrl(imageUrl, token){
  URL = imageUrl; // <- image url
  fetch(URL)
    .then(response => response.blob())
    .then(function (myBlob) {
      const formData = new FormData();
      formData.append('files', myBlob);
      console.log(myBlob)
      fetch(host + "/upload", {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + token, // <- Don't forget Authorization header if you are using it.
        },
        body: formData,
      }).then((response) => {
        const result = response.json()
        return result;
      }).catch(function (err) {
        console.log("error:");
        console.log(err)
      });
    })
}

async function getAdsByLocation(locationId) {
  try {
    const result = await axios.get(host + '/contents/findByLocation/' + locationId)
    return result;
  } catch (e) {
    return e.response;
  }
}

export default {
    login,
    getAdsByLocation,
    uploadImage,
    deleteImage,
    saveAd,
    createAd,
    deleteAd,
    getLocationByExternalId,
    uploadByUrl
}