import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import { recoilPersist } from 'recoil-persist'
import AlertMessage from './alertMessage';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
})

ReactDOM.render(
  <RecoilRoot>
      <App />
      <AlertMessage />
    </RecoilRoot>,
  document.getElementById('root')
);
