import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ValidateBarcode } from './barcodeValidation';
import { useRecoilState } from 'recoil';
import Atoms from './recoil/atoms';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    marginBottom: '30px',
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const EANSelect = (props) => {
  const classes = useStyles();
  const [type, setType] = React.useState('');
  const [alertmessage, setAlertMessage] = useRecoilState(Atoms.alertMessageSettings);

  const handleChange = async (event) => {
    let value = event.target.value; 
    let validationObj = await ValidateBarcode(value, props.barcodeValue); 
    if(validationObj.validation) {       
        setType(value);
        props.setBarcodeType(value);
    }
    setAlertMessage({ open: true, message: validationObj.text, severity: validationObj.severity });
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Streckkodstyp</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleChange}
        >
          <MenuItem value={'CODE128'}>CODE128</MenuItem>
          {/* <MenuItem value={'CODE128A'}>CODE128A</MenuItem>
          <MenuItem value={'CODE128B'}>CODE128B</MenuItem>
          <MenuItem value={'CODE128C'}>CODE128B</MenuItem> */}
          <MenuItem value={'EAN13'}>EAN13</MenuItem>
          <MenuItem value={'EAN8'}>EAN8</MenuItem>
          {/* <MenuItem value={'EAN5'}>EAN5</MenuItem>
          <MenuItem value={'EAN2'}>EAN2</MenuItem>
          <MenuItem value={'UPC'}>UPC</MenuItem>
          <MenuItem value={'CODE39'}>CODE39</MenuItem>
          <MenuItem value={'ITF'}>ITF</MenuItem>
          <MenuItem value={'ITF14'}>ITF14</MenuItem>
          <MenuItem value={'MSI'}>MSI</MenuItem>
          <MenuItem value={'MSI10'}>MSI10</MenuItem>
          <MenuItem value={'MSI11'}>MSI11</MenuItem>
          <MenuItem value={'MSI1010'}>MSI1010</MenuItem>
          <MenuItem value={'MSI1110'}>MSI1110</MenuItem>
          <MenuItem value={'pharmacode'}>pharmacode</MenuItem>
          <MenuItem value={'codabar'}>codabar</MenuItem> */}
          
        </Select>
      </FormControl>
    </div>
  );
}
export default EANSelect;