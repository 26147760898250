export async function ValidateBarcode(type, value) {      
    let returnObj = {
        validation: false,
        severity: 'warning',
        text: 'Streckkod kunde inte skapas'
    }
    const ean13Regex = new RegExp('^[0-9]{12}$');
    const ean8Regex = new RegExp('^[0-9]{7}$');
    const UPCRegex = new RegExp('^[0-9]{12}$');

    if(type === 'EAN8') {
        if(ean8Regex.test(value)) {       
            returnObj.validation = true;
        }
        else {
            returnObj.text = type + " - Ange 7 siffror. Kontrollsiffran läggs till automatiskt";
        }
    }

    if(type === 'EAN13') {       
        if(ean13Regex.test(value)) {       
            returnObj.validation = true;
        }
        else {
            returnObj.text = type + " - Ange 12 siffror. Kontrollsiffran läggs till automatiskt";
        }
    }

    if(type === 'UPC') {       
        if(UPCRegex.test(value)) {       
            returnObj.validation = true;
        }
        else {
            returnObj.text = type + " - endast 12 siffror";
        }
    }

    if(returnObj.validation) {
        returnObj.severity = 'success';
        returnObj.text = 'Streckkod skapades'
    }
    return returnObj;
}