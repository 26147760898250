import 'date-fns';
import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { useRecoilState } from "recoil";
import Atoms from './recoil/atoms';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const DatePickers = (props) => {
  // The first commit of Material-UI
  const [alertmessage, setAlertMessage] = useRecoilState(Atoms.alertMessageSettings);
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18'));
  const [startDate, setStartDate] = useState(true);

  const handleDateChange = (date) => {
    let typedDate = new Date(date);
    if(isValid(props.thisAd, typedDate)) {
      let ISODate = typedDate.toISOString();
      let ad = props.thisAd;
      startDate ? ad.ValidFrom = ISODate : ad.ValidTo = ISODate;
      ad.published = false;
      props.saveDate(ad);
    }
    setSelectedDate(date);
  };

  function isValid(ad, dateToCheck) {
    if(dateToCheck.getTime() === dateToCheck.getTime()) {
      if(!startDate && dateToCheck.toISOString() <= ad.ValidFrom) {
        setAlertMessage({ open: true, message: `Slutdatum måste vara senare än startdatum`, severity: 'warning' }); 
        return false;
      }
      if(startDate && dateToCheck.toISOString() >= ad.ValidTo) {
        setAlertMessage({ open: true, message: `Startdatum måste vara tidigare än slutdatum`, severity: 'warning' });
        return false;
      }
      return true;
  }

  return false;
  }

  useEffect(() => {
    setSelectedDate(props.date);
    setStartDate(props.start);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          format='yyyy-MM-dd'
          id="date-picker-inline"
          label={props.text}
          value={selectedDate}
          onChange={handleDateChange}
          invalidDateMessage={'Giltigt format: ÅÅÅÅ-MM-DD'}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DatePickers;