import React, { useEffect, useState } from 'react';
import { useBarcode } from 'react-barcodes';

const Barcode = (props) => {
  const [value, setValue] = useState(props.textValue);  

    const { inputRef } = useBarcode({
        value: value,
        options: {
        background: '#fff',
        marginTop: '-1px',
        marginBottom: '50px',
        height: '150px',
        width: '3px',
        textMargin: '5px',
        format: props.type,
        fontSize: '30px',
        valid: function(valid) {
            if(valid) {
                let img = document.getElementById('barcode');
                img.style.display = 'block'
                props.setBtnValid(true);
            }
            else {
                let img = document.getElementById('barcode');
                img.style.display = 'none'
                props.setBtnValid(false);
            }
        }
        }
    })

  useEffect(() => {      
        setValue(props.textValue);
  },[props.textValue, props.setBtnValid])
  
  return (
    <img 
        style={{width:'100%'}}
        id="barcode" ref={inputRef} 
        alt=""
    />
  )
};

export default Barcode;