import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { useRecoilState } from "recoil";
import Atoms from './recoil/atoms';
import apiRequests from './apiRequests';
import logo from './d4dportallogo.png';
import DatePickers from './datepicker';
import DropzoneUploadDialog from './dropZone';
import {TextField} from '@material-ui/core';
import ProgressLinear from './progressBar';
import AlertDialog from './alertDialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Tooltip } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ScrollableTabs from './tabs';
import BarcodeDialog from './barcodeDialog';
import UserMenu from './userMenu';

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Den4Dogs AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: theme.spacing(3),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '130%',
    position: 'relative',
  },
  barcodeMedia: {
    paddingTop: '70%',
    position: 'relative',
  },
  cardContent: {
    flexGrow: 1,
  },
  photoIcon: {
    color: '#a00',
    backgroundColor: '#fff',
    height: '50px',
    width: '50px',
    position: 'absolute',
    borderRadius: '20%',
    margin: '15px',
    border: '1px solid #a00',
    cursor: 'pointer',
  },
  iconBtn: {
    borderRadius: 0,
    height: '50px',
    width: '50px',
    position: 'absolute',
    margin: '15px',
    left: 0,
    bottom: '10px',
  },
  footer: {
    backgroundColor: '#a00',
    color: '#fff',
    padding: theme.spacing(2),
  },
  publishBtn: {
    color: '#4caf50',
    marginLeft: '10px',
    width: '35%',
    height: '30px',
  },
  deleteBtn: {
    marginBottom: '30px',
    width: '70%',
  },
}));

const Home = () => {
  const classes = useStyles();
  const [loggedInUser, setLoggedInUser] = useRecoilState(Atoms.loggedInUser);
  const [alertmessage, setAlertMessage] = useRecoilState(Atoms.alertMessageSettings);
  const [ads, setAds] = useState([]);
  const [dropZoneOpen, setDropZoneOpen ] =useState(false);
  const [barcodeZoneOpen, setBarcodeZoneOpen] =useState(false);
  const [alertDialogOpen, setAlertDialogOpen] =useState(false);
  const [currentAd, setCurrentAd] = useState({});
  const [updatedAd, setUpdatedAd] = useState({})
  const [progress, setProgress] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(loggedInUser.locationId[0])

  const setThisLocation = (locationId) => {
    setCurrentLocation(locationId);
  }

  const fetchAds = async() => {
      const response = await apiRequests.getAdsByLocation(currentLocation);
      if(response.data[0] == null) {
        createAd();
        createAd();
        createAd();
      }
      setAds(response.data);
      formatDate(response.data[0])
      
  }

  const createAd = async() => {
    let startDate = new Date();
    let endDate = new Date(new Date().getTime()+(30*24*60*60*1000));
    let ISOStartDate = startDate.toISOString();
    let ISOEndDate = endDate.toISOString();
    
    let data = {
      ValidFrom:ISOStartDate,
      ValidTo:ISOEndDate,
      Name:"Namnge Erbjudande",
      location: 0,
      image:196,
      published:false,
      sortIndex: ads[0] != null ? ads.length + 1 : 1,
      barcode: null,
    }
    let location = await apiRequests.getLocationByExternalId(currentLocation);
    data.location = location.data.id;
    let createdAd = await apiRequests.createAd(data, loggedInUser.token);
    setAlertMessage({ open: true, message: createdAd.data.Name + ` är skapad!`, severity: 'success' });
    setUpdatedAd(createdAd);
    console.debug(createdAd)
    window.scrollTo({ 
      top: document.documentElement.scrollHeight, 
      behavior: 'smooth'
    }); 
  }

  const deleteAd = async (ad) => {
    let deletedAd = await apiRequests.deleteAd(ad.id, loggedInUser.token);
    await apiRequests.deleteImage(deletedAd.data.image.id, loggedInUser.token);
    if(deletedAd.data.barcode) {
      await apiRequests.deleteImage(deletedAd.data.barcode.id, loggedInUser.token);
    }
    await handleOrderDelete(ad);
    setUpdatedAd(deletedAd);
    setAlertMessage({ open: true, message: ad.Name + ` raderades framgångsrikt`, severity: 'success' });
  }

  const editAd = async(ad) => {
    setProgress(true);
    console.debug(ad)
    let savedAd = await apiRequests.saveAd(ad, loggedInUser.token);
      setProgress(false);
    setUpdatedAd(savedAd);
    setAlertMessage({ open: true, message: savedAd.data.Name + ` uppdaterades.`, severity: 'success' });
  }

  const editAdImage = async(image, ad) => {
    setProgress(true);
    let oldImg = ad.image;
    let result = await apiRequests.uploadImage(image, loggedInUser.token);
    ad.image = result.data[0].id; 
    ad.published = false;
    setAlertMessage({ open: true, message: `Bild uppladdad.`, severity: 'success' });
    await apiRequests.deleteImage(oldImg.id, loggedInUser.token); 
    await editAd(ad);
  }

  const editBarcode = async(image, ad) => {
    setProgress(true);
    let oldBarcode = ad.barcode;
    let result = await apiRequests.uploadImage(image, loggedInUser.token);
    ad.barcode = result.data[0].id; 
    ad.published = false;
    setAlertMessage({ open: true, message: `Streckkod uppladdad.`, severity: 'success' }); 
    if(oldBarcode) {
      await apiRequests.deleteImage(oldBarcode.id, loggedInUser.token);
    }
    await editAd(ad);
  }

  const handleEdit = async(e, ad) => {
    const input = e.target.value
    if(ad.Name !== input) {
      ad.Name = input;
      ad.published = false;
      await editAd(ad);
    }
  }

  const handlePublish = async(ad) => {
    ad.published = !ad.published;
    await editAd(ad);
  }

  const handleDropZone = (ad, value) => {
    setDropZoneOpen(value);
    if(ad) {
      setCurrentAd(ad);
    }
  }

  const handleBarcodeDialog = (ad, value) => {
    setBarcodeZoneOpen(value);
    if(ad) {
      setCurrentAd(ad);
    }
  }

  const handleAlertDialog = (ad, value) => {
    setAlertDialogOpen(value);
    if(ad) {
      setCurrentAd(ad);
    }
  }
  const handleOrderDelete = async(deletedAd) => {
    const deletedSortIndex = deletedAd.sortIndex;
    ads.map(async(ad) => {
      if(ad.sortIndex > deletedSortIndex) {
        ad.sortIndex --;
        await apiRequests.saveAd(ad, loggedInUser.token);
      }
    })
    
  }

  const orderAds = async(ad, up) => {
      let currentIndex = ad.sortIndex;
      let targetAd = up ? 
        ads.find(a => a.sortIndex === ad.sortIndex - 1) :
        ads.find(a => a.sortIndex === ad.sortIndex + 1)

      ad.sortIndex = targetAd.sortIndex;
      targetAd.sortIndex = currentIndex;
      await apiRequests.saveAd(ad, loggedInUser.token);
      await apiRequests.saveAd(targetAd, loggedInUser.token);
      await fetchAds();
  }

  const formatDate = (date) => {
      if(date) {
        let newDate = new Date(date).toLocaleDateString();
        return newDate;
      }
  }

  useEffect(() => {       
    fetchAds();

  }, [updatedAd, currentLocation]);      

  useEffect(() => {

  }, [alertDialogOpen, dropZoneOpen, progress, barcodeZoneOpen]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar 
        position="relative"
        style={{ 
            background: '#fff',
            color: '#a00',
            boxShadow: 'none'
        }}
        >
        <Toolbar>
          <img className={classes.logo} src={logo} alt="Logo" />
        <UserMenu />         
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
        <ScrollableTabs 
          createAd={createAd}
          setCurrentLocation={setThisLocation}
        />
        </div>
        {ads.length > 0 ? (
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {ads.map((ad) => (
              ad != null? (
              <Grid item key={ad.id} xs={12} sm={6} md={4}>
                <Card 
                    className={classes.card}> 
                    <TextField 
                      style={{
                        width:'100%',   
                        backgroundColor: '#a00',
                      }}
                      ref={(el) => {
                        if (el) {
                          el.children[0].style.setProperty('color', '#fff', 'important');
                          el.children[0].style.setProperty('font-size', '20px', 'important');
                        }
                      }}
                      component="h2"
                      defaultValue={ad.Name}
                      onBlur={(e) => handleEdit(e, ad)}
                      variant="outlined"
                      multiline
                    >
                    </TextField>
                  <Tooltip title="Klicka för att ladda upp ny bild" interactive>
                  <CardMedia
                    onClick={(e) => handleDropZone(ad, true)}
                    style={{cursor:'pointer'}}
                    className={classes.cardMedia}
                    image={ad.image.url}              
                  >
                  </CardMedia> 
                  </Tooltip>
                  {!ad.barcode ? (
                  <CardActions
                    style={{ justifyContent: 'center'}}
                  >                 
                  <Button 
                    style={{
                      width: '70%',
                      marginTop:'50px',
                      marginBottom: '30px'
                    }}
                    variant="contained" 
                    size="small"
                    color="primary"
                    onClick={() => handleBarcodeDialog(ad, true)}
                  >  
                  Skapa streckkod 
                  </Button>  
                  </CardActions>   
                  ):(
                    <Tooltip title="Klicka för att skapa ny streckkod" interactive>
                    <CardMedia
                    onClick={() => handleBarcodeDialog(ad, true)}
                    style={{cursor:'pointer'}}
                    className={classes.barcodeMedia}
                    image={ad.barcode.url}              
                  >
                    </CardMedia>  
                    </Tooltip>                 
                  )}        
                  <CardContent className={classes.cardContent}>
                      <DatePickers 
                        text='Startdatum för erbjudandet' 
                        start={true} 
                        date={formatDate(ad.ValidFrom)}
                        saveDate={editAd}
                        thisAd={ad}
                      />
                      <DatePickers 
                        text='Slutdatum för erbjudandet' 
                        start={false} 
                        date={formatDate(ad.ValidTo)}
                        saveDate={editAd}
                        thisAd={ad}
                      />
                  </CardContent>
                  <CardActions
                    style={{ justifyContent: 'center'}}
                    >
                      {ad.sortIndex !== 1 && ads.length > 1 ? (
                      <Tooltip title="Flytta upp ett steg i visningsordningen" interactive>
                      <Button 
                        size="small" 
                        variant="contained"
                        onClick={() => orderAds(ad, true)}
                      >
                      <KeyboardArrowUpIcon />
                      </Button>
                      </Tooltip>
                      ) : (null)} 
                      {ad.sortIndex !== ads.length && ads.length > 1 ? (
                      <Tooltip title="Flytta ner ett steg i visningsordningen" interactive>
                      <Button 
                        size="small" 
                        variant="contained"
                        onClick={() => orderAds(ad, false)}
                      >
                      <KeyboardArrowDownIcon />
                      </Button>
                      </Tooltip>
                      ) : (null)} 
                  </CardActions>
                    <CardActions
                      style={{ 
                        justifyContent: 'center',
                        height: '50px'
                      }}
                    > 
                    {ad.published ? (
                      <Tooltip title="Erbjudandet är aktivt, klicka för att avpublicera" interactive>
                      <Button 
                        size="small" 
                        color="secondary"
                        onClick={() => handlePublish(ad)}
                        className={classes.publishBtn}
                      > 
                      Aktiv!   
                      <CheckCircleIcon className={classes.publishBtn}/>                    
                      </Button>
                      </Tooltip>
                      ) : (
                        <Tooltip title="Erbjudandet är ej publicerat, klicka för att aktivera det" interactive>
                        <Button 
                        style={{width: '70%'}}
                        variant="contained" 
                        size="small"
                        color="primary"
                        onClick={() => handlePublish(ad)}
                      >  
                      Publicera                     
                      </Button>
                      </Tooltip>
                      )}
                      </CardActions>   
                      <CardActions
                    style={{ justifyContent: 'center'}}
                  >
                  <Tooltip title="Ta bort detta erbjudande" interactive>
                    <Button 
                      className= {classes.deleteBtn}
                      size="small" 
                      color="secondary"
                      onClick={(e) => handleAlertDialog(ad, true)}
                      variant="contained"
                    >
                      Ta bort
                    </Button>
                    </Tooltip>
                    </CardActions>                               
                </Card>
              </Grid>
              ):(null)
            ))}           
          </Grid>
        </Container>       
        ) : null }
        {alertDialogOpen ? (
          <AlertDialog
            open={alertDialogOpen}
            handleAlertDialog={() => handleAlertDialog()}
            currentAd={currentAd}
            agreeFunction={deleteAd}
          >
          </AlertDialog>
        ) : (null)}
        {dropZoneOpen ? (
        <DropzoneUploadDialog 
          open={dropZoneOpen} 
          handleDropZone={() => handleDropZone()}
          uploadFile={editAdImage}  
          currentAd={currentAd}
        />
        ) : (null)}
        {barcodeZoneOpen ? (
        <BarcodeDialog 
          open={barcodeZoneOpen} 
          handleBarcodeDialog={() => handleBarcodeDialog()}
          uploadBarcode={editBarcode}  
          currentAd={currentAd}
        />
        ) : (null)}
        {progress ? (    
          <ProgressLinear />  
        ) : (null) 
        } 
      </main>
      {/* Footer */}
      {ads.length > 0 ? (
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      ) : (null)}
      {/* End footer */}
    </React.Fragment>
  );
}
export default Home;