import React, { Fragment, useEffect} from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from '../home';
import { useRecoilValue } from 'recoil';
import Atoms from '../recoil/atoms';
import Login from '../login';
import PublicRoute from "./publicroute";

const Router = () => {

    // const style = useRecoilValue(Atoms.style);
    const loggedInUser = useRecoilValue(Atoms.loggedInUser);




    useEffect(() => {

    }, []);


    return (
        <BrowserRouter>
            {
                loggedInUser.authenticated ? (
                    <Fragment>
                        <Switch>
                            <PublicRoute exact path="/" component={Home} />
                        </Switch>
                    </Fragment>


                ) : (
                        <Switch>
                            <PublicRoute exact path="/" component={Login} />
                        </Switch>
                    )
            }
        </BrowserRouter>
    );
};

export default Router;