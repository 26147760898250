import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Menu, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useRecoilState } from "recoil";
import Atoms from './recoil/atoms';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loggedInUser, setLoggedInUser] = useRecoilState(Atoms.loggedInUser);
  const [locationIds, setLocationIds] = useRecoilState(Atoms.locationIds);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setLoggedInUser(
        {
            userName: '',
            token: '',
            authenticated : false,
            role: false,
            locationId: [],
        }
    )
    setLocationIds([]);
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Typography variant="h6" color="inherit" noWrap>
            {loggedInUser.userName}
        </Typography>
      </Button>
      <Menu
        style={{top:'45px'}}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={logout}>LOGGA UT</MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;