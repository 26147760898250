import React, { useState, useEffect } from 'react'
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';

const DropzoneUploadDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);


    const handleClose = () => {
        setOpen(false);
        props.handleDropZone(false)
    }

    const handleSave = (files) => { 
        console.debug(files)
        setFiles(files);
        setOpen(false);
        props.handleDropZone(false)
        props.uploadFile(files, props.currentAd)
    }

    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        setOpen(props.open);
    }, []);

    return (
        <div>
            <Button onClick={handleOpen}>
                Add Image
            </Button>
            <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
                dropzoneText={'Dra och släpp en fil här eller klicka i rutan'}
                filesLimit={1}
                dialogTitle={'Ladda upp bild'}
                showAlerts={['error']}
                submitButtonText={'Ladda upp'}
                cancelButtonText={'Avbryt'}
            />
        </div>
    );
}
export default DropzoneUploadDialog;