import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Barcode from './barcode';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import EANSelect from './dropdown';
import { ValidateBarcode } from './barcodeValidation';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
})
)

const AlertDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState('0');
  const [barcodeType, setBarcodeType] = useState('CODE128');
  const [btnValid, setBtnValid] =useState(false);

  const classes = useStyles();

  const handleBarcodeType = (value) => {      
        setBarcodeType(value);
  }

  const dataURItoBlob = async (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

    const handleBtnValid = (value) => {
        setBtnValid(value);
    }

  const handleAgree = async () => {
    setOpen(false);
    props.handleBarcodeDialog(props.currentAd, false);
    let base64img = document.getElementById('barcode').src;
    let blob = await dataURItoBlob(base64img);
    props.uploadBarcode(blob, props.currentAd)
  };

  const barcodeOnChange = (value) => {
    let validation = ValidateBarcode(barcodeType, value);
        if(validation) {
        setBarcodeValue(value);
    }
  }

  const handleClose = () => {
    props.handleBarcodeDialog(props.currentAd, false);
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
}, [props.open, barcodeValue, btnValid]);

  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle 
        id="alert-dialog-title">{"Skapa streckkod"}
        </DialogTitle>
        <DialogContent>
            <Barcode 
                textValue={barcodeValue}   
                type={barcodeType} 
                setBtnValid={handleBtnValid}       
            />
          <DialogContentText id="alert-dialog-description">            
          </DialogContentText>
          <TextField
            style={{width:'100%'}}
            onChange={(e) => barcodeOnChange(e.target.value)}
          ></TextField>
          <EANSelect
                setBarcodeType={handleBarcodeType}
                barcodeValue={barcodeValue}
            />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleAgree} 
            color="primary"
            disabled={!btnValid}
            >
            Spara till annonsen
          </Button>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AlertDialog;