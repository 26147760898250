import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleAgree = () => {
    setOpen(false);
    props.handleAlertDialog(false)
    props.agreeFunction(props.currentAd)
  };

  const handleClose = () => {
    props.handleAlertDialog(false)
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
}, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bekräfta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Är du säker på att du vill radera " + props.currentAd.Name + " ?"} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} color="secondary">
            Ja
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Nej
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AlertDialog;