import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useRecoilState } from "recoil";
import Atoms from "./recoil/atoms";

export const alertSeverity = {
  success: "success",
  info: "warning",
  warning: "warning",
  error: "error",
};

const AlertMessage = () => {
  const [alertMessageSettings, setAlertMessageSettings] = useRecoilState(
    Atoms.alertMessageSettings
  );
  const open = alertMessageSettings.open;

  const handleClose = () => {
    const updatedSettings = {
      open: false,
      severity: null,
      message: "",
    };
    setAlertMessageSettings(updatedSettings);
  };

  const Alert = (props) => {
    return <MuiAlert elevation={16} variant="filled" {...props} />;
  };

  const getSeverity = () => {
    switch (alertMessageSettings.severity.toLowerCase()) {
      case "success":
        return alertSeverity.success;
      case "info":
        return alertSeverity.info;
      case "warning":
        return alertSeverity.warning;
      case "error":
        return alertSeverity.error;
      default:
        return alertSeverity.success;
    }
  };

  return (
    //   <Slide direction="up" in={open}>
    <Snackbar 
    open={alertMessageSettings.open} 
    autoHideDuration={5000} 
    onClose={() => handleClose()}
   // TransitionComponent={TransitionLeft}
    >
      {
        open ? (
          <Alert onClose={handleClose} severity={getSeverity()}>
            { alertMessageSettings.message}
          </Alert>
        ) : (
            null
          )
      }
    </Snackbar>
  );
};

export default AlertMessage;