import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: 'recoil-persist', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
  })

const loggedInUser = atom ({
    key: 'loggedInUser',
    default: {
        userName: '',
        token: '',
        authenticated : false,
        role: false,
        locationId: [],
    },
    effects_UNSTABLE: [persistAtom],
});

const locationIds = atom ({
    key: 'locationIds',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

const alertMessageSettings = atom ({

    key: 'alertMessageSettings',
    default: {
        open: false,
        severity: null,
        message: '',
    }
});

export default ({
    loggedInUser,
    locationIds,
    alertMessageSettings
});